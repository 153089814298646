<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">

          <router-link :to="'/infevents/info/' + (configId ? configId : '')" class="back-link">
            <svg fill="none" height="12" viewBox="0 0 8 12" width="8" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z" fill="#0033A0"/>
            </svg>
            назад
          </router-link>

          <h1>{{ direction.name }}</h1>

          <table class="monitoring-table">
            <thead>
            <tr>
              <th>Этап</th>
              <th>Дата завершения</th>
              <th>Охват</th>
              <th>Комментарий</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="action in direction.actions" :key="action.id">
              <td :class="getValue(action.id).endDate ? 'green' : 'gray'"><span class="bullet"></span>
                {{ action.name }}
              </td>
              <td>
                <EditableDateField
                    :attrs="{actionId: action.id}"
                    :editable="editable"
                    :input="getValue(action.id).endDate"
                    :output-setter="setEndDate"
                ></EditableDateField>
              </td>
              <template v-if="getValue(action.id).endDate">
                <td>
                  <EditableTextField
                      :attrs="{actionId: action.id}"
                      :editable="editable"
                      :input="getValue(action.id).scale"
                      :output-setter="setScale"
                      nolabel
                      write-text="Укажите охват"
                  ></EditableTextField>
                </td>
                <td>
                  <EditableTextAreaField
                      :attrs="{actionId: action.id}"
                      :editable="editable"
                      :input="getValue(action.id).comment"
                      :output-setter="setComment"
                      nolabel
                      write-text="Напишите комментарий"
                  ></EditableTextAreaField>
                </td>
              </template>
              <template v-else>
                <td></td><td></td><td></td>
              </template>
            </tr>
            </tbody>
          </table>


          <div v-if="editable" class="save-buttons">
            <v-btn outlined @click="$router.push('/infevents/info/' + (configId ? configId : ''))">отмена</v-btn>
            <v-btn color="primary" @click="submit">Сохранить изменения</v-btn>
          </div>
        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../styles/main.scss";
</style>

<script>
import EditableDateField from "./elements/EditableDateField";
import EditableTextAreaField from "./elements/EditableTextAreaField";
import EditableTextField from "./elements/EditableTextField";

export default {
  name: "InfeventsEdit",
  props: {},
  components: {EditableTextField, EditableTextAreaField, EditableDateField},
  data() {
    return {
      configId: null,
      config: null,
      direction: null,
      values: null,
      apiLoaded: false,
      errorText: null,
      editable: false,
      apiFreeze: false //во избежание многократного нажатия кнопки сохранить
    };
  },
  methods: {
    async loadConfig() {
      let req
      if (this.configId == null) {
        req = await this.$getApi("/infevents/getActiveConfig")
      } else {
        req = await this.$getApi("/infevents/getConfig", {id: this.configId})
      }
      if (req.ok) {
        this.config = req.payload
        this.config.directions = JSON.parse(this.config.directionsJson)
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    extractDirection() {
      for (let direction of this.config.directions) {
        if (direction.id == this.directionId) {
          this.direction = direction
          return
        }
      }
      this.errorText = "Ошибка: некорректный номер направления или конфигурационный файл"
    },

    async loadStageValues() {
      let req = await this.$getApi("/infevents/getDirectionStageValues",
          {userName: this.$behalfUser, configId: this.config.id, directionId: this.directionId})
      if (req.ok) {
        this.values = req.payload
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    async submit() {
      if (this.apiFreeze) {
        return
      }
      this.apiFreeze = true

      let validationResult = this.validateStageValues()
      if (validationResult === true) {
        let req = await this.$postApi("/infevents/saveDirectionStageValues", {
          userName: this.$user.username,
          configId: this.config.id,
          directionId: this.direction.id,
          infeventValues: this.values
        })
        if (req.ok) {
          this.$router.push('/infevents/info/' + (this.configId ? this.configId : ''))
        } else {
          this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        }
      } else {
        this.errorText = validationResult
      }
      this.apiFreeze = false
    },

    validateStageValues() {
      return true
    },

    getValue(actionId) {
      for (let v of this.values) {
        if (v.directionId === this.directionId && v.actionId === actionId) {
          return v
        }
      }
      return {}
    },

    setField(actionId, field, value) {
      let objectValue = this.getValue(actionId)
      if (objectValue.directionId == null) {
        let obj = {
          userName: this.$user.username,
          configId: this.config.id,
          directionId: this.directionId,
          actionId: actionId,
        }
        obj[field] = value
        this.values.push(obj)
      } else {
        objectValue[field] = value
      }
      this.$forceUpdate();
    },

    setEndDate(endDate, attrs) {
      this.setField(attrs.actionId, "endDate", endDate)
    },
    setComment(comment, attrs) {
      this.setField(attrs.actionId, "comment", comment)
    },
    setScale(val, attrs) {
      if(Number.isInteger(+val) && !val.includes('.'))
        this.setField(attrs.actionId, "scale", val)
      else(alert('Допускаются только целочисленные значения'))
    }
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    this.configId = this.$route.params.configId
    this.directionId = +this.$route.params.directionId
    await this.loadConfig()
    this.extractDirection()
    await this.loadStageValues()
    this.editable = (this.$user.role==='ROLE_CZN_MANAGER' || this.$user.role==='ROLE_CZN_TERRITORIAL') && (this.config.active)
    this.apiLoaded = true
  }
};
</script>


<style lang="scss">
@import "../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>
