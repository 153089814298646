<template v-if="editMode">
  <div v-if="editable">
    <div v-if="editMode">
      <v-text-field v-if="editMode" v-model="textValue" :label="label" ref="inp" @focusout="onInput" autofocus></v-text-field>
    </div>
    <div v-else-if="input">
      <template v-if="nolabel==null">{{label}}: </template><a href="#" @click.prevent="startEdit()" class="comment-text">{{ input | lengthFilter}}</a>
    </div>
    <div v-else>
      <a href="#" @click.prevent="startEdit()">{{writeText}}</a>
    </div>
  </div>
  <div v-else>
    <template v-if="nolabel==null">{{label}}: </template>{{ input | lengthFilter}}
  </div>
</template>

<style scoped>
.comment-text{
  white-space: pre-line;
}
</style>

<script>
export default {
  name: 'EditableTextField',
  props: ['label', 'writeText', 'input', 'outputSetter', 'attrs', 'nolabel', 'editable'],

  data: () => ({
    textValue: null,
    editMode: false
  }),
  methods: {
    onInput() {
      this.outputSetter(this.textValue, this.attrs)
      this.editMode = false
    },

    startEdit(){
      this.textValue = this.input
      this.editMode = true
    }
  },
  filters: {
    lengthFilter(val){
      const maxShowLength = 150
      if(val != null && val.length > maxShowLength){
        return val.slice(0, maxShowLength-3) + "..."
      }
      return val
    }
  },
  updated() {

  }

}
</script>
