<template>
  <v-menu
      v-if = editable
      :close-on-content-click="true"
      min-width="auto"
      offset-y
      transition="scale-transition"
  >
    <template v-if="input!=null" v-slot:activator="{ on, attrs }">
      <a href="#" @click.prevent="" v-bind="attrs" v-on="on">{{ input | dateFormat }}</a>
    </template>
    <template v-else v-slot:activator="{ on, attrs }">
      <a href="#" @click.prevent=""  v-bind="attrs" v-on="on">Укажите дату</a>
    </template>
    <v-date-picker
        v-model="datePicked"
        :max="maxDate"
        :min="minDate"
        locale="ru-RU"
        no-title
        scrollable
        @input="onInput"
    >
    </v-date-picker>
  </v-menu>
  <div v-else>
    {{ input | dateFormat }}
  </div>
</template>

<script>
export default {
  name: 'EditableDateField',
  props: ['input', 'outputSetter', 'editable', 'attrs'],

  data: () => ({
    datePicked: null,
    minDate: '2019-01-01',
    maxDate: new Date().toJSON().slice(0,10)
  }),
  methods: {
    onInput() {
      this.outputSetter(this.datePicked, this.attrs)
    }

  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  }

}
</script>
